<template>
	<!--Login Start-->
    <div>
        <!-- Loader -->
        <v-overlay
        :opacity="1"
        :value="overlay"
        >
        <v-progress-circular indeterminate size="64">
            Loading...
        </v-progress-circular>
        </v-overlay>
    </div>  
</template>

<script>
import {mapActions} from 'vuex'
export default {

	data: () => ({
			overlay: true
	}),
  components: {
    
  },
  methods: {
    ...mapActions(['validarToken']),
  },
  computed: {
  },

  async created() {
    console.log('Verificando Usuario...')
    await this.validarToken(this.$route.params.token)
    console.log('Verificación terminada...')
  } 
}
</script>

<style>

</style>